/* eslint-disable react/display-name */
import React from 'react'
import Layout from 'components/layout'
import { getHomePage } from 'lib/api/home-page'
import { getResourcesByTag, getSiteResources, getCommonCopy } from 'lib/api/resources'
import HeroSection from 'components/page-sections/hero-section'
import TestimonialCarouselSection from 'components/page-sections/testimonial-carousel-section/testimonial-carousel-section'
import Section from 'components/section/section'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import styles from 'styles/home-page.module.css'
import GenericPageSection from 'components/page-sections/generic-page-section'
import Card from 'components/card/card'
import { ICommonCopyDict } from 'interfaces/common-copy'
import { IUiResources } from 'interfaces/ui-resource'
import BlogPostsSection from 'components/blog-posts-section'
import { IPost } from 'interfaces/post'
import { preFooterIconSectionInternalName } from 'misc-variables'
import { rxHomeHeroExperimentName } from 'constants/growthbook'
import IconsSection from 'components/page-sections/icons-section'
import PageSection from 'components/page-sections'
import { IPageSectionTestimonials } from 'interfaces/page-section-testimonials'
import { IHomePage } from 'interfaces/home-page'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import { IPageSectionComparisonTable } from 'interfaces/page-section-comparison-table'
import { IPageSectionIcons } from 'interfaces/page-section-icons'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import { IPageSectionForm } from 'interfaces/page-section-form'
import Link from 'next/link'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { IPageSectionProductCategories } from 'interfaces/page-section-product-categories'
import { useFeature } from '@growthbook/growthbook-react'

const headerRenderNodeOptions = {
    [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
        <h2 className="my-0 section--header leading-10 lg:leading-13 tracking-tight">{children}</h2>
    ),
}

const subheaderRenderNodeOptions = {
    [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
        <span className="text-xl lg:text-2xl leading-140% lg:leading-120% tracking-tight">{children}</span>
    ),
}

interface IProps {
    preview: boolean
    homePage: IHomePage
    commonCopy: ICommonCopyDict
    uiResources: IUiResources
}

export default function Home({ homePage, preview = false, uiResources, commonCopy }: IProps): React.ReactElement {
    const {
        internalName,
        sectionsCollection,
        floatingCta,
        pageSectionTestimonial,
        pageSectionHero,
        bottomBlogPostsCollection,
        heroSectionsCollection,
    } = homePage

    const homePageSections = sectionsCollection?.items || []

    let ourPromiseSection
    let preFooterIconSection

    // filter out pre footer section  and our promise section
    // as they're rendered in a different place
    // todo: i think we've updated this in Contentful, we might not need this anymore
    const filteredHomePageSections = homePageSections?.filter((section) => {
        if (section?.internalName === 'Section 5 - Our Promise') {
            ourPromiseSection = section
            return false
        }
        if (section?.internalName === preFooterIconSectionInternalName) {
            preFooterIconSection = section
            return false
        }

        return true
    })

    const testimonialSection = pageSectionTestimonial as IPageSectionTestimonials

    const floatingCtaElRef = React.useRef<HTMLDivElement>()
    const [heroEl, setHeroEl] = React.useState<HTMLDivElement>()

    const heroElRef = React.useCallback((node) => {
        if (node !== null) {
            setHeroEl(node)
        }
    }, [])

    const { entry } = useIntersectionObserver({ element: heroEl })

    const hasHeroSectionExitedViewport = !entry?.isIntersecting

    React.useEffect(() => {
        if (hasHeroSectionExitedViewport === false) {
            document.documentElement.style.setProperty('--fixedToBottomElementHeight', '0px')
        } else {
            if (floatingCtaElRef.current) {
                const floatingElHeight = floatingCtaElRef.current?.clientHeight

                // on ocassions the cleanup function's property assignment
                // would prevent property assignment here, adding a timeout
                // as a fix
                setTimeout(() => {
                    document.documentElement.style.setProperty('--fixedToBottomElementHeight', floatingElHeight + 'px')
                }, 0)
            }
        }

        return () => {
            document.documentElement.style.setProperty('--fixedToBottomElementHeight', '0px')
        }
    }, [hasHeroSectionExitedViewport])

    const showFloatingCta = hasHeroSectionExitedViewport

    const rxHomeHeroFeature = useFeature(rxHomeHeroExperimentName)

    let heroSection = pageSectionHero

    // we're running an experiment with rx hero for now
    // ideally we'd want this to work with all home pages
    // perhaps a more generic experiment and use index as variations
    if (internalName === 'Rx Takeover - SharkTank') {
        const hero: 'rx-search' | 'token-visualizer' = rxHomeHeroFeature?.value ?? 'rx-search'

        if (hero === 'rx-search') {
            const rxSearchHero = heroSectionsCollection.items.find(
                (heroSection) => heroSection.internalName === 'Hero [Rx LP]',
            )
            heroSection = rxSearchHero ?? pageSectionHero
        } else {
            const tokenVisualizerHero = heroSectionsCollection.items.find(
                (heroSection) => heroSection.internalName === 'Token Visualizer Hero',
            )
            heroSection = tokenVisualizerHero ?? pageSectionHero
        }
    }

    return (
        <Layout preview={preview}>
            {heroSection && (
                <div ref={heroElRef}>
                    <HeroSection pageSectionHero={heroSection} uiResources={uiResources} commonCopy={commonCopy} />
                </div>
            )}

            {filteredHomePageSections?.map((pageSection) => {
                if (pageSection?.__typename === 'Post') {
                    const post = pageSection as IPost

                    // featured post
                    return (
                        <section className="section" key={post.sys?.id}>
                            <Card
                                title={post.title}
                                cardTitle={commonCopy?.['featuredArticleLabel']?.value}
                                ctaLabel={uiResources?.['readArticleLabel']?.value as string}
                                ctaLink={'/blogs/journal/' + post.slug}
                                description={post.excerpt}
                                image={post.coverImage}
                                layout="horizontal"
                                imageAlign={post.imageSide}
                                background={post.featuredCardBackgroundColor}
                            />
                        </section>
                    )
                }

                const pageSectionWithoutPost = pageSection as
                    | IPageSectionComparisonTable
                    | IGenericPageSection
                    | IPageSectionIcons
                    | IPageSectionTestimonials
                    | IPageSectionVisualizer
                    | IPageSectionForm
                    | IPageSectionProductCategories

                return (
                    <PageSection
                        uiResources={uiResources}
                        key={pageSection?.sys?.id}
                        section={pageSectionWithoutPost}
                        commonCopy={commonCopy}
                    />
                )
            })}

            {testimonialSection?.renderType === 'Cabinet Team' ? (
                <TestimonialCarouselSection uiResources={uiResources} section={testimonialSection} />
            ) : null}

            {/* our promise section */}
            <GenericPageSection section={ourPromiseSection} uiResources={uiResources} />

            {/* blog posts */}
            {bottomBlogPostsCollection?.items?.length > 0 && (
                <Section>
                    <ContentfulRichText
                        className="blog-posts-header"
                        renderNode={headerRenderNodeOptions}
                        content={commonCopy?.['bottomOfPageBlogPostsSectionHeader']?.value?.json}
                    />
                    <div className="mt-5">
                        <ContentfulRichText
                            className="blog-posts-header"
                            renderNode={subheaderRenderNodeOptions}
                            content={commonCopy?.['bottomOfPageBlogPostsSectionSubheader']?.value?.json}
                        />
                    </div>
                    {/* render as carousel if more than one */}
                    <div className={`mt-6 lg:mt-7 ${styles['blog-posts-carousel-wrapper']}`}>
                        <BlogPostsSection
                            commonCopy={commonCopy}
                            uiResources={uiResources}
                            posts={bottomBlogPostsCollection?.items}
                        />
                    </div>
                </Section>
            )}

            {/* pre footer section */}
            {preFooterIconSection && <IconsSection section={preFooterIconSection} />}

            {/* floatinc cta - visible after hero section exits viewport */}
            {showFloatingCta && floatingCta?.href && (
                <div
                    ref={floatingCtaElRef}
                    className="w-full lg:hidden cabinet-container px-5 py-4 bg-white fixed bottom-0 shadow-sm-lift z-40"
                >
                    <Link href={floatingCta?.href} className="button button-primary cursor-pointer block">
                        {floatingCta?.name}
                    </Link>
                </div>
            )}
        </Layout>
    )
}

export async function getStaticProps({ preview }) {
    const homePage = await getHomePage(preview)
    const uiResources = await getSiteResources()
    const commonCopy = await getCommonCopy(['userInterfaceHomePage'])
    const homePageUIResources = await getResourcesByTag(['userInterfaceHomePage'])

    return {
        props: {
            preview: preview ?? false,
            homePage: homePage,
            commonCopy: commonCopy,
            // todo: cleanup, only fetch page specific resources
            uiResources: { ...uiResources, ...homePageUIResources },
        },
    }
}
